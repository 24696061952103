import getFromCdn from '@anm/helpers/getFromCdn';
import { FC } from 'react';

import MetaHead from '../../../MetaHead';

export type LoginMetaHeadProps = { path?: string };

const LoginMetaHead: FC<LoginMetaHeadProps> = ({ path }) => (
  <MetaHead
    path={path}
    title="Login"
    description="Sign in to your Wave.video account to сreate, edit, host, and share engaging video content. Explore the world of video today!"
    preview={getFromCdn('images/data/meta_preview.jpg')}
    size={{ width: 400, height: 300 }}
  />
);

export default LoginMetaHead;
