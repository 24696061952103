import { TermsUseProvider } from '@anm/components/TermsUse';
import { getWaveLinks } from '@anm/data/links';
import compose from 'lodash/fp/compose';
import React, { FC } from 'react';

import bootstrapPage, { BootstrapPageProps } from '../HOCs/bootstrapPage';
import injectSagasAndReducers from '../HOCs/injectSagasAndReducers';
import withLoginRedirect from '../HOCs/withLoginRedirect';
import { TitleTextVariants } from '../components/auth/AuthSection';
import LoginMetaHead from '../components/auth/login/LoginMetaHead';
import LoginSection from '../components/auth/login/LoginSection';
import Layout from '../components/layouts/Layout';
import vtConfig from '../config';
import collapseReducer, { collapseSaga } from '../store/modules/collapse';

export const titleTextVariants: TitleTextVariants = {
  welcome: {
    title: 'Welcome back',
    description:
      'Sign in now to enjoy our latest updates, stunning new templates, and upcoming events in our social calendar.'
  }
};

const { urls, isProd } = vtConfig();
const { termsUse, privacyPolicy } = getWaveLinks({
  isProd,
  waveUrl: urls.wave
});

const LogInPage: FC<BootstrapPageProps> = ({ path }) => {
  return (
    <>
      <LoginMetaHead path={path} />
      <Layout navigationVariant="white" mainVariant="contain">
        <TermsUseProvider
          value={{
            termsLink: termsUse,
            privacyLink: privacyPolicy
          }}
        >
          <LoginSection titleTextVariants={titleTextVariants} backgroundVariant="white-to-light-blue" />
        </TermsUseProvider>
      </Layout>
    </>
  );
};

export default compose(
  bootstrapPage,
  withLoginRedirect,
  injectSagasAndReducers(
    () => [...collapseSaga()],
    {
      collapse: collapseReducer
    },
    { id: 'LogInPage' }
  )
)(LogInPage);
