import Background from '@anm/components/Background';
import Container from '@anm/components/Container';
import TermsUse from '@anm/components/TermsUse';
import TitleBlock from '@anm/components/TitleBlock';
import { TitleDescription } from '@anm/components/TitleBlock/Wrapper';
import { media, stretchToScreenHeight } from '@anm/styles/helpers';
import styled from 'styled-components';

export const AuthContentTabletStyles = media.tablet`
  padding: 20px 20px 76px;
  justify-content: center;
`;

export const AuthContentDesktopStyles = media.desktop`
  padding: 30px 20px 76px;
`;

export const AuthSectionLeftSideTabletStyles = media.tablet`
  margin-bottom: 40px;
`;

export const AuthSectionsMobileStyles = media.tablet`
  width: 100%;
  max-width: 100%;
`;

export const AuthContent = styled(Container)`
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  max-width: 760px;
  padding: 30px 30px 76px;
  justify-content: space-between;
  ${AuthContentDesktopStyles};
  ${AuthContentTabletStyles};
`;

export const AuthContentWrapper = styled(Background)`
  display: flex;
  align-items: center;
  ${stretchToScreenHeight};
`;

export const AuthSectionLeftSide = styled.div`
  max-width: 350px;
  ${AuthSectionLeftSideTabletStyles};
  ${AuthSectionsMobileStyles};
`;
export const AuthSectionRightSide = styled.div`
  width: 304px;

  form {
    button {
      margin-top: 10px;
    }
    > * {
      width: 100%;
    }
  }
  ${AuthSectionsMobileStyles};
`;

const AuthSectionWrapper = styled.div``;

export const AuthTitleBlock = styled(TitleBlock)`
  ${TitleDescription} {
    margin-top: 16px;
  }
  span {
    display: block;
    margin-bottom: 13px;
  }
`;

export const TermsUseAuth = styled(TermsUse)`
  margin-top: 25px;
  display: block;
`;

export default AuthSectionWrapper;
