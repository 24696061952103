import { createAction, ActionUnion } from '../../helpers/createAction';

export const EXTEND_ITEM = 'wave.video/collapse/ADD_EXTENDED_ITEM';
export const COLLAPSE_ITEM = 'wave.video/collapse/COLLAPSE_ITEM';
export const TOGGLE_COLLAPSE = 'wave.video/collapse/TOGGLE_COLLAPSE';

export const collapseActions = {
  extendItem: (id: string) => createAction(EXTEND_ITEM, { id }),
  collapseItem: (id: string) => createAction(COLLAPSE_ITEM, { id }),
  toggleCollapse: (id: string) => createAction(TOGGLE_COLLAPSE, { id })
};

export type CollapseActions = ActionUnion<typeof collapseActions>;
