import { NextPageWrapper } from 'anm-next';
import { NextPage } from 'next';
import { SagaGenerator } from 'typed-redux-saga/dist';

import { AsyncReducers } from '../store/types';

type MetaData = { id: string };

const injectSagasAndReducers = (
  asyncSagas: () => SagaGenerator<any>[],
  asyncReducers: AsyncReducers,
  { id } = {} as MetaData
) => <T extends NextPage>(BaseComponent: T) => {
  (BaseComponent as any).id = id;
  (BaseComponent as any).asyncSagas = asyncSagas;
  (BaseComponent as any).asyncReducers = asyncReducers;

  return (BaseComponent as any) as NextPageWrapper;
};

export default injectSagasAndReducers;
