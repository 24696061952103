import { EmailField, RequiredPasswordField } from '@anm/components/form/fields';
import { Omit } from 'helpers';
import { FC } from 'react';

import { SubmitForm, SubmitFormProps } from '../../SubmitForm';

type LoginFormProps = Omit<SubmitFormProps, 'validators' | 'renderFields'>;
const renderLoginFields = () => (
  <>
    <EmailField />
    <RequiredPasswordField />
  </>
);

const LoginForm: FC<LoginFormProps> = props => (
  <SubmitForm forgotLink={true} renderFields={renderLoginFields} {...props} />
);

export default LoginForm;
