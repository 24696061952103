import { compose, mapProps, withHandlers, withState } from 'recompose';

import withAuth, { WithAuth } from '../../../../../HOCs/withAuth';
import { PositionProps } from '../../../../../HOCs/withPosition';
import { SubmitFormFields } from '../../../SubmitForm';

import withLoginStepPosition, {
  EMAIL_AUTH_VIEW,
  SOCIAL_EMAIL_VIEW
} from './withLoginStepPosition';

type HOCs = WithAuth & PositionProps;
type Handlers = {
  handleLoginEmailClick: () => void;
  handleEmailAuthSubmit: (emailAuthFields: SubmitFormFields) => void;
  handleBackClick: () => void;
};

export type StepViewPosition =
  | typeof SOCIAL_EMAIL_VIEW
  | typeof EMAIL_AUTH_VIEW;

export type WithLoginStepsHandlersProps = Handlers &
  HOCs &
  WithLoginStepsHandlersOwnProps;

export type WithLoginStepsHandlersOwnProps = {
  onStepChange: (step: StepViewPosition) => void;
};

export default compose<WithLoginStepsHandlersProps, {}>(
  withAuth,
  withLoginStepPosition,
  withState('currentStep', 'onStepChange', SOCIAL_EMAIL_VIEW),
  mapProps<WithLoginStepsHandlersProps, WithLoginStepsHandlersProps>(
    ({ position, onStepChange, ...other }) => ({
      ...other,
      position,
      onStepChange: step => {
        position.next(step);
        onStepChange(step);
      }
    })
  ),
  withHandlers<WithLoginStepsHandlersProps, Handlers>({
    handleLoginEmailClick: ({ onStepChange }) => () =>
      onStepChange(EMAIL_AUTH_VIEW),

    handleEmailAuthSubmit: ({ login }) => (emailAuthFields: SubmitFormFields) =>
      login(emailAuthFields),

    handleBackClick: ({ onStepChange }) => () => onStepChange(SOCIAL_EMAIL_VIEW)
  })
);
