import { toObject } from '@anm/helpers/parseParams';
import { ComponentType } from 'react';
import { compose, lifecycle } from 'recompose';

import withPosition, {
  PositionProps,
  PositionState
} from '../../../../../HOCs/withPosition';

export const SOCIAL_EMAIL_VIEW = 0;
export const EMAIL_AUTH_VIEW = 1;

type HOCs = PositionProps;

const renderEmailFormIfEmailProvided = ({ current, next }: PositionState) => {
  const { email } = toObject(location.search);
  if (!email || current === EMAIL_AUTH_VIEW) return;
  next(EMAIL_AUTH_VIEW);
};

function componentDidUpdate(this: { props: HOCs }) {
  const { position } = this.props;
  renderEmailFormIfEmailProvided(position);
}

export default <P extends HOCs>(BaseComponent: ComponentType<P & HOCs>) =>
  compose<HOCs & P, P>(
    withPosition(),
    lifecycle<HOCs, P>({ componentDidUpdate })
  )(BaseComponent);
