import { BackgroundVariants } from '@anm/components/Background';
import { TitleBlockProps, TitleBlockSizes } from '@anm/components/TitleBlock';
import { FC } from 'react';

import AuthSectionWrapper, {
  AuthContent,
  AuthContentWrapper,
  AuthSectionLeftSide,
  AuthSectionRightSide,
  AuthTitleBlock,
  TermsUseAuth
} from './Wrapper';

export type AuthTitleBlockVariants = 'welcome';

export type TitleTextVariants = {
  [key in AuthTitleBlockVariants]: TitleBlockProps;
};

export type AuthSectionProps = {
  titleSize?: TitleBlockSizes;
  titleTextVariants: TitleTextVariants;
  backgroundVariant: BackgroundVariants;
};

const AuthTitleBlockSizeMap: Partial<
  {
    [key in BackgroundVariants]: TitleBlockSizes;
  }
> = {
  white: 'large',
  'blue-light': 'large',
  'white-to-light-blue': 'large',
  'blue-dark-light': 'super-large',
  'white-to-dark-blue': 'extra-large'
};

const AuthSection: FC<AuthSectionProps> = ({
  children,
  titleTextVariants,
  backgroundVariant,
  titleSize = AuthTitleBlockSizeMap[backgroundVariant] || 'large'
}) => (
  <AuthSectionWrapper>
    <AuthContentWrapper variant={backgroundVariant}>
      <AuthContent>
        <AuthSectionLeftSide>
          <AuthTitleBlock size={titleSize} {...titleTextVariants['welcome']} />
          <TermsUseAuth />
        </AuthSectionLeftSide>

        <AuthSectionRightSide>{children}</AuthSectionRightSide>
      </AuthContent>
    </AuthContentWrapper>
  </AuthSectionWrapper>
);

export default AuthSection;
