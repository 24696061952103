import { Reducer } from 'redux';

import { CollapseActions, COLLAPSE_ITEM, EXTEND_ITEM } from './actions';
import { CollapseState } from './types';

const initialCollapseState: CollapseState = {
  extendList: []
};

const collapseReducer: Reducer<CollapseState, CollapseActions> = (
  state = initialCollapseState,
  action
) => {
  switch (action.type) {
    case EXTEND_ITEM: {
      const { id } = action.payload;
      return {
        ...state,
        extendList: [...state.extendList, id]
      };
    }
    case COLLAPSE_ITEM: {
      const { id } = action.payload;
      const newExtendList = state.extendList.filter(item => {
        const req = new RegExp(`^${id}`);
        return !req.test(item);
      });
      return {
        ...state,
        extendList: newExtendList
      };
    }
    default:
      return state;
  }
};

export default collapseReducer;
