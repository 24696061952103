import { isAnon } from '@anm/auth/helpers/authAnon';
import hasAuthAccess from '@anm/auth/helpers/hasAuthAccess';
import { useEffect } from 'react';
import { compose, hoistStatics, InferableComponentEnhancer } from 'recompose';

import withHook from './withHook';
import withRedirects, { WithRedirectsProps } from './withRedirects';
import withUser, { WithUserProps } from './withUser';

type SignUpRedirectProps = WithUserProps & WithRedirectsProps & Handlers;
type Handlers = {
  showEmailConfirmNotification: () => void;
};

const useLoginRedirectCreator = ({ user, loginRedirect }: SignUpRedirectProps) => {
  const isUserLogged = hasAuthAccess() && !isAnon();

  useEffect(() => {
    if (!isUserLogged || !user.profile) {
      return;
    }

    loginRedirect();
  }, [user.profile?.userId, isUserLogged]);
};

const withLoginRedirect = compose(
  withUser,
  withRedirects,
  withHook(useLoginRedirectCreator)
) as InferableComponentEnhancer<{}>;

export default hoistStatics(withLoginRedirect);
