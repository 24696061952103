import { ActionWithPayload } from 'store';
import { call, put, select, take } from '@anm/helpers/saga/effects';


import { collapseActions, TOGGLE_COLLAPSE } from './actions';
import { isExtended } from './selectors';

function* toggleCollapse() {
  while (true) {
    const action: ActionWithPayload<string, { id: string }> = yield take(
      TOGGLE_COLLAPSE
    );
    const { id } = action.payload;

    const isOpened = yield* select(isExtended(id));

    if (isOpened) {
      yield put(collapseActions.collapseItem(id));
    } else {
      yield put(collapseActions.extendItem(id));
    }
  }
}

const collapseWatchers = () => [call(toggleCollapse)];

export default collapseWatchers;
