import { FC } from 'react';

import SignUpButton from '../AuthButtons/SignUpButton';

import AuthQuestionBlock from './AuthQuestionBlock';

const NoAccountQuestion: FC = () => (
  <AuthQuestionBlock>
    Don’t have an account?{' '}
    <SignUpButton variant="blue-link" size="link-medium" />
  </AuthQuestionBlock>
);

export default NoAccountQuestion;
