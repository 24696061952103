import { FC } from 'react';

import AuthSection, { AuthSectionProps } from '../../AuthSection';
import LoginSteps from '../LoginSteps';

const LoginSection: FC<AuthSectionProps> = props => (
  <AuthSection {...props}>
    <LoginSteps />
  </AuthSection>
);

export default LoginSection;
