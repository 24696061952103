import ViewSwitcher from '@anm/components/ViewSwitcher';
import { FC } from 'react';
import styled from 'styled-components';

import BackButton from '../../BackButton';
import SocialEmailButtonsBlock from '../../SocialEmailButtonsBlock';
import NoAccountQuestion from '../../questions/NoAccountQuestion';
import LoginForm from '../LoginForm';

import withSignUpStepsHandlers, {
  WithLoginStepsHandlersProps
} from './HOCs/withLoginStepsHandlers';

const LoginWrapper = styled.div`
  min-height: 258px;
`;

type HOCs = WithLoginStepsHandlersProps;

const errorCodeMap: { [key in number | string]: string } = {
  403: 'Incorrect email or password'
};
const SignUpSteps: FC<HOCs> = ({
  auth,
  position: { current },
  handleBackClick,
  handleLoginEmailClick,
  handleEmailAuthSubmit
}) => (
  <ViewSwitcher active={current}>
    <SocialEmailButtonsBlock
      onEmailButtonClick={handleLoginEmailClick}
      renderQuestionBlock={() => <NoAccountQuestion />}
    >
      Sign in
    </SocialEmailButtonsBlock>

    <LoginWrapper>
      <LoginForm
        onSubmit={handleEmailAuthSubmit}
        isPending={auth.isPending}
        submitError={
          auth.error && (errorCodeMap[auth.error.code] || auth.error.message)
        }
      >
        Continue
      </LoginForm>
      <BackButton onClick={handleBackClick}>
        Sign in with a social account
      </BackButton>
    </LoginWrapper>
  </ViewSwitcher>
);

export default withSignUpStepsHandlers(SignUpSteps);
